@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;700;900&display=swap");

@layer components {
  .monserate_font {
    font-family: "Montserrat", sans-serif;
  }
}

.modalBg{
	background: linear-gradient(136.88deg, rgba(0, 79, 255, 0.4) -3.51%, rgba(81, 73, 176, 0.4) 48.24%, rgba(247, 115, 41, 0.4) 100%);

}

.mobileModalBg{
	@media screen and (max-width: 960px){
		background: linear-gradient(136.88deg, #004FFF -3.51%, #5149B0 48.24%, #F77329 100%);
	}
}

.gradBg{
  background: linear-gradient(136.88deg, rgba(0, 79, 255, 0.4) -3.51%, rgba(81, 73, 176, 0.4) 48.24%, rgba(247, 115, 41, 0.4) 100%);
}

::-webkit-scrollbar-thumb {
  background: #003d99;
  border-radius: 5px;
  height: 2rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #003d99;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.up {
  transform: translate(-50%, -50%) rotate(0deg);
  transition: transform 0.5s ease;
}

.down{
  transform: translate(-50%, -50%) rotate(180deg);
  transition: transform 0.5s ease;
}
.borderIncrease{
  transition: transform 0.5s ease;
}



input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}



